import React from "react";
import { graphql } from "gatsby";
import Markdown from "react-markdown";
import Img from "gatsby-image";
import LazyLoad from "react-lazy-load";
import Layout from "../components/Layout";
import Particle from "../components/Particle";
import SEO from "../components/Seo";
import Contact from "../components/Contact";
import $ from "jquery";

const propTypes = {};
const defaultProps = {};

export default class HomePageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  componentDidMount() {
    $(".call_to_action a").on("click", function(event) {
      var $anchor = $(this);
      $("html, body")
        .stop()
        .animate({ scrollTop: $($anchor.attr("href")).offset().top - 0 }, 1500);
      event.preventDefault();
    });
  }

  render() {
    const post = this.props.data.markdownRemark;
    const seo = post.frontmatter.seo ? post.frontmatter.seo : "";
    const breadcrumb = post.frontmatter.breadcrumb
      ? post.frontmatter.breadcrumb
      : "";
    const structureddata = post.frontmatter.structureddata
      ? post.frontmatter.structureddata
      : "";
    const title = post.frontmatter.title ? post.frontmatter.title : "";
    const pagetitle = post.frontmatter.pagetitle
      ? post.frontmatter.pagetitle
      : "";
    const pagecontent = post.frontmatter.pagecontent
      ? post.frontmatter.pagecontent
      : "";
    const pagecontent2 = post.frontmatter.pagecontent2
      ? post.frontmatter.pagecontent2
      : "";
    const pagecontent3 = post.frontmatter.pagecontent3
      ? post.frontmatter.pagecontent3
      : "";
    const heroimage = post.frontmatter.heroimage
      ? post.frontmatter.heroimage
      : null;
    const contentimage = post.frontmatter.contentimage
      ? post.frontmatter.contentimage
      : null;
    const maincontent = post.frontmatter.maincontent
      ? post.frontmatter.maincontent
      : "";
    const secondcontent = post.frontmatter.secondcontent
      ? post.frontmatter.secondcontent
      : "";
    const lastcontent = post.frontmatter.lastcontent
      ? post.frontmatter.lastcontent
      : "";


    return (
      <Layout>
        <SEO
          seo={seo}
          structureddata={structureddata}
          breadcrumb={breadcrumb}
        />

        {/* Home Section */}
        <section className="section bg-home height-100vh" id="home">
          <Particle />
          <div className="bg-overlay" />
          <div className="container slidero">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-white text-center">
                <LazyLoad placeholder={
                <div>
                  <h1>{title}</h1>
                  <h4 className="padding-t-15">An Agency With Proven Online Marketing Results Let us help in
                  Elevating your Brands Get Started</h4>
                </div>
                }>
                  <h1 className="home-title">{title}</h1>
                </LazyLoad>
                <h4 className="padding-t-15">
                  An Agency With Proven Online Marketing Results Let us help in
                  Elevating your Brands Get Started
                </h4>
                <button className="call_to_action">
                  <a href="#contact" className="nav-link" style={{color:"#fff"}}>
                    Request a Call Back
                  </a>
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* Hero section finish */}

        {/*Main Section */}
        <section id="main" className="section pt-5">
          <div className="container">
            <div className="row">
              <div className=" pagecontent col-lg-12">
                <h2 className="main-title text-center"> {pagetitle} </h2>
                <div className="section-title-border mt-3" />
                {/* <LazyLoad placeholder={<p> { JSON.stringify(pagecontent) } </p>}> */}
                  <Markdown escapeHtml={false} source={pagecontent} />
                {/* </LazyLoad> */}
              </div>
            </div>
          </div>
        </section>
        {/*  */}

        {/* page content-2 */}
        <section className="section bg-light pt-5 pb-5" id="digital-market">
          <div className="container">
            <div className="row page-content vertical-content">
              <div className="col-lg-7">
                <div className="features-box page-content-box">
                {/* <LazyLoad placeholder={<p>{pagecontent2} </p>}> */}
                    <Markdown escapeHtml={false} source={pagecontent2} />
                    {/* </LazyLoad> */}
                </div>
              </div>

              <div className="col-lg-5">
                <div className="features-img features-right text-right page-content-image">
                <LazyLoad placeholder={ <img src={contentimage.publicURL} alt="feature" /> }>

                    {!!contentimage && !!contentimage.childImageSharp ? (
                      <Img
                        fluid={contentimage.childImageSharp.fluid}
                        alt="feature"
                        className="img-fluid"
                      />
                    ) : (
                      <img src={contentimage.publicURL} alt="feature" />
                    )}

                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}

        {/* pagecotent-3 */}
        <section id="main" className="section pt-5">
          <div className="container">
            <div className="row">
              <div className=" pagecontent-3 col-lg-12">
                {/* <LazyLoad placeholder={<p>{pagecontent3} </p>}> */}
                  <Markdown escapeHtml={false} source={pagecontent3} />
                {/* </LazyLoad> */}
              </div>
            </div>
          </div>
        </section>
        {/*  */}

        {/* service Section */}

        <section className="section fluid-background" id="services">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="section-title text-center"> Our Services </h1>
                  <div className="section-title-border mt-3" />
                  <p className="section-subtitle text-white text-center pt-4 font-secondary">
                  We craft digital, graphic and dimensional thinking, to create
                  category leading brand experiences that have meaning and add a
                  value for our clients.
                  </p>
                </div>
              </div>
            </div>
          </section>


        <section className="section pt-0 " >
          <div className="container">
            <div className="row margin-t-30">
              <div className="col-lg-4 margin-t-20">
                <div className="services-box text-center hover-effect">
                  <i className="fas fa-search" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{maincontent[0].content.column1} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={maincontent[0].content.column1}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
              <div className="col-lg-4 margin-t-20">
                <div className="services-box text-center hover-effect">
                  <i className="fas fa-laptop" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{maincontent[0].content.column2} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={maincontent[0].content.column2}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
              <div className="col-lg-4 margin-t-20">
                <div className="services-box text-center hover-effect">
                  <i className="fas fa-handshake" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{maincontent[2].content.column1} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={maincontent[2].content.column1}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
            </div>
            <div className="row margin-t-30">
              <div className="col-lg-4 margin-t-20">
                <div className="services-box text-center hover-effect">
                  <i className="fas fa-desktop" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{maincontent[1].content.column2} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={maincontent[1].content.column2}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
              <div className="col-lg-4 margin-t-20">
                <div className="services-box text-center hover-effect">
                  <i className="fas fa-search-plus" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{maincontent[1].content.column1} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={maincontent[1].content.column1}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
              <div className="col-lg-4 margin-t-20">
                <div className="services-box text-center hover-effect">
                  <i className="fas fa-code" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{maincontent[2].content.column2} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={maincontent[2].content.column2}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* End */}

        {/* Digital market section */}
        <section className="section bg-light pt-5 pb-5" id="digital-market">
          <div className="container">
            <div className="row vertical-content">
              <div className="col-lg-7">
                <div className="features-box">
                  {/* <LazyLoad placeholder={<p>{secondcontent[0].row} </p>} > */}
                    <Markdown
                      escapeHtml={false}
                      source={secondcontent[0].row}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>

              <div className="col-lg-5">
                <div className="features-img features-right text-right">
                  <LazyLoad placeholder={ <img src={heroimage.publicURL} alt="feature" /> } >
                    {!!heroimage && !!heroimage.childImageSharp ? (
                      <Img
                        fluid={heroimage.childImageSharp.fluid}
                        alt="feature"
                        className="img-fluid"
                      />
                    ) : (
                      <img src={heroimage.publicURL} alt="feature" />
                    )}
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}

          <section className="section pt-5 pb-5 fluid-background">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="section-title text-center">
                    NETART SEO ACTIVITIES
                  </h1>
                  <div className="section-title-border mt-3" />
                  <p className="section-subtitle text-white text-center pt-4 font-secondary">
                    Netart SEO activities beyond the current SEO companies
                    trends.
                  </p>
                </div>
              </div>
            </div>
          </section>

        {/* sectios */}
        <section className="section pt-5 pb-5" id="digital-section">
          <div className="container">
            <div className="row margin-t-50">
              <div className="col-lg-4 ">
                <div className="text-center pricing-box hover-effect min500">
                  {/* <LazyLoad
                    placeholder={
                      <p>{lastcontent[0].content.column1} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={lastcontent[0].content.column1}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>

              <div className="col-lg-4 ">
                <div className="text-center pricing-box hover-effect min500">
                  {/* <LazyLoad
                    placeholder={
                      <p>{lastcontent[0].content.column2}</p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={lastcontent[0].content.column2}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>

              <div className="col-lg-4 ">
                <div className="text-center pricing-box hover-effect min500">
                  {/* <LazyLoad
                    placeholder={
                      <p>{lastcontent[2].content.column1} </p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={lastcontent[2].content.column1}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-light">
          <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="section-title text-center">Work Process</h1>
                  <div className="section-title-border margin-t-20" />
                  <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
                    In an ideal world this website wouldn’t exist, a client
                    would acknowledge the importance of having web copy before
                    the design starts.
                  </p>
                </div>
            </div>
            <div className="row">
              <div className="col-lg-6 text-center process-left-icon-1">
                <i className="fas fa-angle-right" />
              </div>
              <div className="col-lg-6 text-center process-left-icon-2">
                <i className="fas fa-angle-right" />
              </div>
            </div>
            <div className="row margin-t-50">
              <div className="col-lg-4 plan-line">
                <div className="text-center process-box">
                  <i className="fas fa-toggle-on" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{lastcontent[1].content.column1}</p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={lastcontent[1].content.column1}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
              <div className="col-lg-4 plan-line">
                <div className="text-center process-box">
                  <i className="fas fa-toggle-off" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{lastcontent[1].content.column2}</p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={lastcontent[1].content.column2}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="text-center process-box">
                  <i className="fas fa-rupee-sign" />
                  {/* <LazyLoad
                    placeholder={
                      <p>{lastcontent[2].content.column2}</p>
                    }
                  > */}
                    <Markdown
                      escapeHtml={false}
                      source={lastcontent[2].content.column2}
                    />
                  {/* </LazyLoad> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Contact />
      </Layout>
    );
  }
}

HomePageTemplate.propTypes = propTypes;
HomePageTemplate.defaultProps = defaultProps;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      tableOfContents
      frontmatter {
        seo {
          google {
            itemprop {
              content
              metaname
            }
            metaname {
              content
              name
            }
            name
            url
          }
          graph {
            graphlink
            graphlinkurl
            graphmeta {
              graphcontent
              graphmetaname
            }
            graphproperty {
              graphcontent
              graphmetaname
            }
          }
          link {
            name
            url
          }
          meta {
            description
            metaname
          }
          title
          twitter {
            description
            name
          }
        }
        structureddata {
          data
        }
        breadcrumb {
          item
          name
        }
        title
        pagetitle
        pagecontent
        pagecontent2
        pagecontent3
        contentimage{
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroimage {
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid
            }
          }
        }
        maincontent {
          content {
            column1
            column2
          }
        }
        secondcontent {
          row
        }

        lastcontent {
          content {
            column1
            column2
          }
        }
      }
    }
  }
`;
