import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const propTypes = {
  seo: PropTypes.object,
  breadcrumb: PropTypes.array,
  structureddata:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
};

const defaultProps = {
  structureddata: "",
  breadcrumb: "",
  seo: "",
};

export default class Seo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    
    const data = {
      "@context": "http://schema.org/",
      "@type": "BreadCrumbList",
      itemListElement: this.props.breadcrumb
        ? this.props.breadcrumb.map((x, index) => {
            return {
              "@type": "ListItem",
              position: index + 1,
              name: x.name,
              item: x.item,
            };
          })
        : " ",
    };

    const title= this.props.seo.title
    const siteUrl  = this.props.seo.link.url
    return (
    <React.Fragment>
      <Helmet
            title={title}
            link={[
              {
                rel: 'canonical',
                href: `${siteUrl}`
              },
              {
                rel: 'amphtml',
                href: `${siteUrl}amp/`
              }
            ]}
            
          >
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />

        {this.props.seo.meta.map((x, index) => (
          'metaname' in x ? <meta key={index + 1} name={x.metaname} content={x.description} /> : null
        ))}

{/* Google Plus graph dada */}
        {'itemprop' in this.props.seo.google
          ? this.props.seo.google.itemprop.map((x, index) => (
            'metaname' in x ? <meta key={index + 1} itemProp={x.metaname} content={x.content} /> : null
            ))
          : " "}
        {'metaname' in this.props.seo.google
          ? this.props.seo.google.metaname.map((x, index) => (
            'metaname' in x ? <meta key={index + 1} name={x.name} content={x.content} /> : null
            ))
          : " "}

        {
          this.props.seo.google ? ( <link rel={this.props.seo.google.name} href={this.props.seo.google.url } /> ) : ( " " )
        }

{/* Graph dada */}
        {'graphmeta' in this.props.seo.graph
          ? this.props.seo.graph.graphmeta.map((x, index) => (
              <meta
                key={index + 1}
                itemProp={x.graphmetaname}
                content={x.graphcontent}
              />
            ))
          : " "}
        {'graphproperty' in this.props.seo.graph
          ? this.props.seo.graph.graphproperty.map((x, index) => (
              <meta
                key={index + 1}
                name={x.graphmetaname}
                content={x.graphcontent}
              />
            ))
          : " "}

        {this.props.seo.graph ? (
          <link
            rel={
              this.props.seo.graph
                ? this.props.seo.graph.graphlink
                : " "
            }
            href={
              this.props.seo.graph
                ? this.props.seo.graph.graphlinkurl
                : " "
            }
          />
        ) : (
          ""
        )}
{/* Twitter Card graph dada */}
        {this.props.seo.twitter
          ? this.props.seo.twitter.map((x, index) => (
              <meta key={index + 1} name={x.name} content={x.description} />
            ))
          : " "}

        {/* Structured Data */}
        {data ? (
          <script type="application/ld+json">
            {`
            ${JSON.stringify(data)} 
          `}
          </script>
        ) : (
          <script type="application/ld+json">
          </script>
        )}

        {this.props.structureddata
          ? this.props.structureddata.map((x,index) => (
              <script key={index + 1} type="application/ld+json">
                {` 
            ${x.data} 
            `}
              </script>
            ))
          : 
          <script type="application/ld+json">
            
          </script>
          }


{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-136756381-2"></script>
<script>{`
 window.dataLayer = window.dataLayer || [];
 function gtag(){dataLayer.push(arguments);}
 gtag('js', new Date());

 gtag('config', 'UA-136756381-2');`}
</script>

      {/* <!-- Google Tag Manager --> */}
      <script>
    {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K554NFQ');
    `}
    </script>
    
</Helmet>
</React.Fragment>

    );
  }
}

Seo.propTypes = propTypes;
Seo.defaultProps = defaultProps;
