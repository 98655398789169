import React from "react";
import Modal from "react-responsive-modal";
import "../styles/page/contact.css";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, open: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => this.setState({ open: true }))
      .catch(error => alert(error));
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <>
        <section className="section fluid-background" id="contact">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="section-title text-center">GET IN TOUCH</h1>
                <div className="section-title-border mt-3" />
                <p className="section-subtitle text-white text-center pt-4 font-secondary">
                  We thrive when coming up with innovative ideas but also
                  understand that a smart concept should be supported with
                  measurable results.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="mt-4 pt-4">
                  <p className="mt-4">
                    <span className="h5">Office Address</span>
                    <br />
                    <span className="text-muted d-block mt-2">
                      A.K Complex, 13/17, Tagore Rd, Ganapathy, Coimbatore,
                      Tamil Nadu 641006
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Mobile</span>
                    <br />
                    <a
                      title="Call to +91 9789720718"
                      href="tel:+919789720718"
                    >
                      +91 978 972 0718
                    </a>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Mail</span>
                    <br />
                    <a
                      title="mailto:work@netart.io"
                      href="mailto:work@netart.io"
                      target="_top"
                    >
                      work@netart.io
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="custom-form mt-4 pt-4">
                  <div id="message"> </div>

                  <form
                    id="contact-form"
                    method="post"
                    name="contact"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                    netlify="true"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="hidden"
                          name="form-name"
                          value="contact"
                        />
                        <div hidden>
                          <label>
                            Don’t fill this out:
                            <input
                              name="bot-field"
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>

                        <div className="form-group mt-2">
                          <input
                            className="form-control"
                            id={"name"}
                            name={"name"}
                            type={"text"}
                            placeholder="Your name*"
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mt-2">
                          <input
                            className="form-control"
                            id={"email"}
                            name={"email"}
                            placeholder="Your email*"
                            type={"email"}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div style={{height:"60px"}} class="form-group col-md-1 col-sm-1 d-flex justify-content-center align-items-center">
                        <span> + </span>
                      </div> */}
                      <div className="col-lg-2">
                        <div className="form-group mt-2">
                          <input
                            className="form-control"
                            id={"countrycode"}
                            name={"countrycode"}
                            placeholder="CC"
                            type={"number"}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-2">
                          <input
                            className="form-control"
                            id={"mobilenumber"}
                            name={"mobilenumber"}
                            placeholder="Your Mobile"
                            type={"number"}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mt-2">
                          <textarea
                            className="form-control"
                            id={"message"}
                            name={"message"}
                            placeholder="Your message..."
                            rows="4"
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        <input
                          className="submitBnt btn btn-custom"
                          id="submit"
                          name="send"
                          type="submit"
                          value="Send Message"
                        />
                        <div id="simple-msg" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <h1 className="section-title text-center">
            Thanks for Contacting us. One of our Representatives will be in
            contact with you shortly
          </h1>
          <div className="section-title-border margin-t-20"> </div>
          <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
            If you ever have any questions that require immediate
            assistance, please call us at
            <a title="Call to +91 9789720718" href="tel:+919789720718">
              +91 978 972 0718.
            </a>
          </p>
        </Modal>
      </>
    );
  }
}
